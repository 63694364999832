li.subcategory a {
    color: #84868c;
    text-decoration: none;
    font-family: Arial, Verdana;
    font-size: 12px;
    font-weight: normal;
}

li.subcategory a:hover {
    color: red;
    text-decoration: none;
}

li.subcategory {
/*  list-style-image: url("http://extendvi.vot.pl/img/listyle.png");*/
	list-style-type: " / ";
	color: red;
}