div.catalog a {
    text-decoration: none;
    font: 12px/20px Arial, Verdana, Tahoma;
    color: #868789;
    font-weight: bold;
}

div.catalog a:hover {
    color: red;
    text-decoration: none;
    transition: transform .2s;
    transform: scale(1.5);
}

div.catalog a.red-link {
    color: red;
}

div.catalog img{
    width: 100%;
    height: auto;
}

div.catalog {
    font: 12px/20px Arial, Verdana, Tahoma;
    color: #868789;
}

div.catalog h1 {
    font: 14px/20px Arial, Verdana, Tahoma;
    color: #868789;
    font-weight: bold;
}

div.catalog h2 {
    font: 14px/20px Arial, Verdana, Tahoma;
    color: #868789;
    font-weight: bold;
}

div.catalog h3 {
    font: 14px/20px Arial, Verdana, Tahoma;
    color: #868789;
    font-weight: bold;
}