.gallery-thumbnail div {
	height: 200px;
}

div.gallery-thumbnail a:hover {
    color: red;
    text-decoration: none;
}

div.gallery-thumbnail:hover {
	transform: scale(1.04);
}

.gallery-thumbnail div:hover {
	color: red;
}