img.image-gallery-thumbnail-image {
	width: 120px;
	height: 120px;
}

button.image-gallery-thumbnail {
	width: 120px;
	height: 120px;
}

button.image-gallery-thumbnail:hover {
	transform: scale(1.05);
	color: red;
	border: none;
}

button.image-gallery-thumbnail.active {
	border: none;
}

button.image-gallery-icon:hover {
	color: red;
}