.categories {
    padding: 10px;
}

div.categories {
	color: #84868c;
    text-decoration: none;
    font-family: Arial, Verdana;
    font-size: 17px;
    font-weight: bold;
}