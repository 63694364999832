.img-carousel {
    padding: 20px;
    width: 100%;
    max-width: 800px;
    height: 100%;
    margin: auto;
}

.img-arrows i:hover {
    color: red;
    cursor: pointer;
}

.img-arrows i {
    color: #84868c;
    text-decoration: none;
    font-family: Arial, Verdana;
    font-size: 14px;
    font-weight: bold;
}

div.img-arrows > i {
    padding-right: 20px;
    padding-left: 20px;
}

.img-arrows {
    padding: 15px;
}

p.counter-1-of-n {
    color: #84868c;
    text-decoration: none;
    font-family: Arial, Verdana;
    font-size: 14px;
    font-weight: bold;
}