div.txt {
	font: 12px/20px Arial, Verdana, Tahoma;
	color: #868789;
}

div.txt h1{
	font: 24px/20px Arial, Verdana, Tahoma;
	color: #868789;
	font-weight: normal;
}

div.txt h2{
	font: 12px/20px Arial, Verdana, Tahoma;
	color: #868789;
	font-weight: bold;
}

div.txt h3{
	font: 12px/20px Arial, Verdana, Tahoma;
	color: #868789;
	font-weight: bold;
}

p.title-main {
	font: 24px/20px Arial, Verdana, Tahoma;
}

p.title-submain {
	font: 18px/20px Arial, Verdana, Tahoma;
}

a.txt-href {
	text-decoration: none;
	font-weight: bold;
	color: red;
}

a.txt-href:hover {
    color: red;
    text-decoration: none;
    transition: transform .2s;
    transform: scale(1.5);
    color: #868789;;
}

.top-expo-produkty img{
	width: 100%;
  	height: auto;
}

.top-expo-produkty-mid img{
	width: 50%;
  	height: auto;
  	padding: 5px;
}