div.contact {
	font: 16px Arial, Verdana, Tahoma;
	color: #868789;
	line-height: 80%;
}

div.contact h1 {
	font: 16px Arial, Verdana, Tahoma;
	color: #868789;
	font-weight: bold;
}

div.contact h2 {
	font: 16px Arial, Verdana, Tahoma;
	color: #868789;
	font-weight: bold;
}

div.contact h3 {
	font: 16px Arial, Verdana, Tahoma;
	color: #868789;
	font-weight: bold;
}

a.contact-email {
	color: red;
}