div.product-thumbnail a {
    text-decoration: none;
    font: 12px/20px Arial, Verdana, Tahoma;
	color: #868789;
}

div.product-thumbnail a:hover {
    color: red;
    text-decoration: none;
    transition: transform .2s;
    transform: scale(1.5);
}

div.product-thumbnail:hover {
	transform: scale(1.02);
}