div.category a {
    color: #84868c;
    text-decoration: none;
    font-family: Arial, Verdana;
    font-size: 17px;
}

div.category a:hover {
    color: red;
    text-decoration: none;
    transition: transform .2s;
    transform: scale(1.5);
}

div.category-group {
	/*background: url('https://www.expozycja.com/img/left_menu_spacer.gif') bottom left no-repeat;*/
	border-bottom: 1px solid #84868c;
    border-right: 1px solid #84868c;
  	border-radius: 5px;
  	padding-left: 15px;
}