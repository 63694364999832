div.header-custom a {
    color: #84868c;
    text-decoration: none;
    font-family: Arial, Verdana;
    font-size: 20px;
}

div.header-custom a:hover {
    color: red;
    text-decoration: none;
    transition: transform .2s;
    transform: scale(1.5);
}

div.header-custom a.red-link {
    color: red;
}
